<template>
  <router-view />
</template>
<script>
export default {
  watch: {
    '$route' () {
      this.setTitle()
    }
  },
  created () {
    this.setTitle()
  },
  methods: {
    setTitle () {
      const title = 'SSID'
      if (this.$route && this.$route.meta && this.$route.meta.title) {
        document.title = this.$route.meta.title + ' | ' + title
      }
    }
  }
}
</script>