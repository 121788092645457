import CONFIG from '@/config/config'

export const LocalStorage = {
  getItem (name: string) {
    return localStorage.getItem(name)
  },
  setItem (name: string, value: any) {
    return localStorage.setItem(name, value)
  },
  removeItem (name: string) {
    return localStorage.removeItem(name)
  },
  getToken () {
    try {
      return localStorage.getItem(CONFIG.JWT)
    } catch (error) {
      return localStorage.getItem(CONFIG.JWT)
    }
  },
  setToken (token: string) {
    return localStorage.setItem(CONFIG.JWT, token)
  },
  removeToken () {
    return this.removeItem(CONFIG.JWT)
  },
}