import CONFIGS from '@/config/config'
import router from '@/router'
import { LocalStorage } from '@/utils/LocalStorage'
const RouterInterceptor = {
  install () {
    router.beforeEach(async (to, from, next) => {
      // Check page is required authenticated
      if (to.meta.requiresAuth) {
        const isLogin = LocalStorage.getItem(CONFIGS.IS_LOGGED)
        if (isLogin !== 'true') {
          next({
            name: 'Login',
          })
          LocalStorage.removeItem(CONFIGS.IS_LOGGED)
          LocalStorage.removeToken()
        } else {
          next()
        }
      } else { // Page is not required authenticated
        next()
      }
    })
  }
}
export default RouterInterceptor
