import { defineStore } from "pinia";

export const useCommonStore = defineStore({
  id: 'common',
  state: () => ({
    _isStickyMenu: false as boolean,
    _isShowMenu: false as boolean,
    _isShowSidebar: false as boolean,
    _isShowSubmenu: false as boolean,
    _activeIndex: 0 as number,
  }),
  getters: {
    isStickyMenu: (state) => state._isStickyMenu,
    isShowMenu: (state) => state._isShowMenu,
    isShowSidebar: state => state._isShowSidebar,
    isShowSubmenu: state => state._isShowSubmenu,
    activeIndex: state => state._activeIndex,
  },
  actions: {
    stickyMenu (data: boolean) {
      this._isStickyMenu = data
    },
    toggleMenu (data: boolean) {
      this._isShowMenu = data
    },
    toggleSidebar () {
      this._isShowSidebar = !this._isShowSidebar
    },
    toggleSubmenu () {
      this._isShowSubmenu = !this._isShowSubmenu
    },
    closeSubmenu () {
      this._isShowSubmenu = false
    },
    activeLink (index: number) {
      this._activeIndex = index
      if (window.innerWidth < 1200) {
        this._isShowSidebar = false
      }
    },
  }
})