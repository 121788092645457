export default {
  BASE_URL: process.env.VUE_APP_BASE_URL,
  PATH_INDEX: {
    HOME: 0,
    CREATE: 1,
    USER_MANAGEMENT: 2,
    PAIR_MANAGEMENT: 3,
    POOL_MANAGEMENT: 4,
  },
  RESPONSE_STATUS: {
    SUCCESS: 'Success',
    ERROR: 'Error',
  },
  HTTP_STATUS: {
    UNAUTHORIZED: 401
  },
  ACTION_TYPE: {
    ADD: 1,
    UPDATE: 2
  },
  IS_LOGGED: 'bridge_admin_isLogged',
  JWT: 'bridge_admin_store',
  SESSION_STOGRATE: 'bridge_session'
}