import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// Layout
import Layout from '@/views/layouts/Layout.vue'

// Pages
import Home from "../views/pages/home/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Layout,
    redirect: { name: 'Home' },
    children: [
      {
        path: '/:id?',
        name: 'Home',
        component: Home,
        meta: { title: 'Home' }
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
